/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-sign.html'
(function () {
  'use strict';
  angular.module('app').component('orderNewSign', {
    template: theTemplate,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      newOrderObj: '=',
      newOrderDetail: '=',
      inResend: '=',
      orderIdToModify: '=',
      inModify: '=',
      isSampleMethod: '<',
      mandatoryComments: '<'
    }),
    controller: orderNewSignController
  });
  orderNewSignController.$inject = [
    'orderService',
    'toastr',
    '$state',
    '$scope',
    'customerService',
    '$filter',
    'logService',
    '$stateParams',
    'getAllOrdersService',
    'regexService',
    'escapedMessageService'
  ];
  function orderNewSignController(
    orderService,
    toastr,
    $state,
    $scope,
    customerService,
    $filter,
    logService,
    $stateParams,
    getAllOrdersService,
    regexService,
    escapedMessageService
  ) {
    var vm = this;

    vm.disableButton = true;
    vm.disableResendButton = true;

    //functions
    vm.orderObj = {};
    vm.saveOrder = saveOrder;
    vm.resendOrder = resendOrder;
    vm.disableAddOrderButton = true;
    vm.disableResendOrderButton = true;
    vm.currentState = $state.current.name;

    /**
     * Method for disable button
     */
    $scope.$watch(
      'vm.newOrderObj',
      function () {
        if (vm.newOrderObj) {
          var isValidPin = false;
          var isValidFirstName = false;
          var isValidLastName = false;
          if (!vm.newOrderObj.patientInfo.noPersonalIdentityNumber) {
            isValidPin = customerService.validatePersonalNumber(
              vm.newOrderObj.patientInfo.personalIdentity
            );
          } else {
            if (
              vm.newOrderObj.patientInfo.personalIdentity &&
              vm.newOrderObj.patientInfo.personalIdentity.length >= 3
            ) {
              isValidPin = true;
            }
          }
          var isConstructionCreated =
            vm.newOrderObj.selectedConstructions.fixedConstructions.bridges.length >
              0 ||
            vm.newOrderObj.selectedConstructions.fixedConstructions.implantBridges
              .length > 0 ||
            vm.newOrderObj.selectedConstructions.fixedConstructions.implantCrowns
              .length > 0 ||
            vm.newOrderObj.selectedConstructions.fixedConstructions.posts.length >
              0 ||
            vm.newOrderObj.selectedConstructions.fixedConstructions.others.length >
              0 ||
            vm.newOrderObj.selectedConstructions.removableConstructions
              .otherRemovables.length > 0 ||
            vm.newOrderObj.selectedConstructions.fixedConstructions.singleCrowns
              .length > 0 ||
            vm.newOrderObj.selectedConstructions.removableConstructions
              .completeDentures.length > 0 ||
            vm.newOrderObj.selectedConstructions.removableConstructions
              .partialDentures.length > 0 ||
            vm.newOrderObj.selectedConstructions.removableConstructions
              .temporaryDentures.length > 0 ||
            vm.newOrderObj.selectedConstructions.removableConstructions.bitetrays
              .length > 0;

          var isMandatoryComments = false;
          if (vm.mandatoryComments && !vm.newOrderObj.deliveryInfo.information) {
            isMandatoryComments = true;
          }

          if (vm.newOrderObj.patientInfo.firstName) {
            isValidFirstName = regexService.nameRegExp(
              vm.newOrderObj.patientInfo.firstName
            );
          }
          if (vm.newOrderObj.patientInfo.lastName) {
            isValidLastName = regexService.nameRegExp(
              vm.newOrderObj.patientInfo.lastName
            );
          }

          if (
            vm.newOrderObj.patientInfo.firstName &&
            vm.newOrderObj.patientInfo.lastName &&
            vm.newOrderObj.patientInfo.personalIdentity &&
            vm.newOrderObj.selectedLab &&
            vm.newOrderObj.selectedConstructions.type &&
            isValidPin &&
            isValidFirstName &&
            isValidLastName &&
            vm.newOrderObj.deliveryInfo.clinic &&
            vm.newOrderObj.deliveryInfo.date &&
            !(vm.isSampleMethod && !vm.newOrderObj.sample.type) &&
            isConstructionCreated &&
            !isMandatoryComments
          ) {
            vm.disableButton = false;
          } else {
            vm.disableButton = true;
          }
        }
      },
      true
    );

    function removeTempCommentsFromConstruction(abutmentTeeth) {
      Object.keys(abutmentTeeth).forEach(function (key) {
        abutmentTeeth[key].forEach(function (construct) {
          delete construct.tempComments;
          delete construct.allComments;
        });
      });
    }

    /**
     * Method to save Order Details
     */
    function saveOrder() {
      vm.disableAddOrderButton = false;
      if (vm.newOrderObj) {
        var orderObject = {
          patient: {
            firstName: vm.newOrderObj.patientInfo.firstName,
            lastName: vm.newOrderObj.patientInfo.lastName,
            personalIdentityNumber: vm.newOrderObj.patientInfo.personalIdentity
          },
          lab: vm.newOrderObj.selectedLab,
          files: [],
          messages: [],
          deliveryDetails: {
            date: '',
            destination: '',
            pickUpLocation: ''
          },
          additionalInfo: '',
          constructions: {
            type: '',
            abutmentTeeth: {
              singleCrowns: [],
              bridges: [],
              posts: [],
              implantCrowns: [],
              implantBridges: [],
              completeDentures: [],
              partialDentures: [],
              temporaryDentures: [],
              bitetrays: [],
              others: [],
              otherRemovables: []
            }
          },
          sampleDisinfected: vm.newOrderObj.sampleDisinfected
        };

        orderObject.clinic = vm.currentUser.organization;

        if (vm.newOrderObj.files) {
          for (var i in vm.newOrderObj.files) {
            orderObject.files.push({ file: vm.newOrderObj.files[i] });
          }
        }
        if (vm.newOrderObj.patientInfo.noPersonalIdentityNumber) {
          orderObject.patient.noPersonalIdentityNumber =
            vm.newOrderObj.patientInfo.noPersonalIdentityNumber;
        }
        orderObject.type = 'Order_1';
        orderObject.deliveryDetails.date = new Date(
          new Date(vm.newOrderObj.deliveryInfo.date)
        ).getTime();
        orderObject.deliveryDetails.destination = vm.newOrderObj.deliveryInfo.clinic;
        orderObject.deliveryDetails.pickUpLocation =
          vm.newOrderObj.deliveryInfo.pickUpLocation;
        orderObject.additionalInfo = escapedMessageService.getEscapedMessage(
          vm.newOrderObj.deliveryInfo.information
        );
        orderObject.deliveryDetails.status = vm.newOrderObj.deliveryInfo.status;
        orderObject.deliveryDetails.exportOrder =
          vm.newOrderObj.deliveryInfo.exportOrder;
        orderObject.deliveryDetails.isPickUpAtClinic =
          vm.newOrderObj.deliveryInfo.isPickUpAtClinic;

        if (vm.newOrderObj.sample) {
          vm.newOrderObj.sample.created = {
            at: Date.now(),
            by: vm.currentUser.name
          };
          orderObject.deliveryDetails.sample = vm.newOrderObj.sample;
        }

        if (
          vm.newOrderObj.selectedConstructions.fixedConstructions ||
          vm.newOrderObj.selectedConstructions.removableConstructions
        ) {
          if (vm.newOrderObj.selectedConstructions.type === 'fixed') {
            orderObject.constructions.type = 'Fixed';
          } else {
            orderObject.constructions.type = 'Removable';
          }
          orderObject.constructions.abutmentTeeth.singleCrowns =
            vm.newOrderObj.selectedConstructions.fixedConstructions.singleCrowns;
          orderObject.constructions.abutmentTeeth.implantCrowns =
            vm.newOrderObj.selectedConstructions.fixedConstructions.implantCrowns;
          orderObject.constructions.abutmentTeeth.bridges =
            vm.newOrderObj.selectedConstructions.fixedConstructions.bridges;
          orderObject.constructions.abutmentTeeth.posts =
            vm.newOrderObj.selectedConstructions.fixedConstructions.posts;
          orderObject.constructions.abutmentTeeth.others =
            vm.newOrderObj.selectedConstructions.fixedConstructions.others;
          orderObject.constructions.abutmentTeeth.otherRemovables =
            vm.newOrderObj.selectedConstructions.removableConstructions.otherRemovables;
          orderObject.constructions.abutmentTeeth.implantBridges =
            vm.newOrderObj.selectedConstructions.fixedConstructions.implantBridges;
          orderObject.constructions.abutmentTeeth.completeDentures =
            vm.newOrderObj.selectedConstructions.removableConstructions.completeDentures;
          orderObject.constructions.abutmentTeeth.partialDentures =
            vm.newOrderObj.selectedConstructions.removableConstructions.partialDentures;
          orderObject.constructions.abutmentTeeth.temporaryDentures =
            vm.newOrderObj.selectedConstructions.removableConstructions.temporaryDentures;
          orderObject.constructions.abutmentTeeth.bitetrays =
            vm.newOrderObj.selectedConstructions.removableConstructions.bitetrays;
          removeTempCommentsFromConstruction(
            orderObject.constructions.abutmentTeeth
          );
          orderObject.isNewOrder = true;
        }

        orderObject.dentist = vm.currentUser._id;

        return orderService
          .saveDetail(orderObject)
          .then(function (order) {
            if (order) {
              toastr.success($filter('translate')('ORDER_CREATED'));
              vm.disableAddOrderButton = true;
              window.top.postMessage(
                {
                  eventType: 'NOTIFY_NEW_ORDER',
                  order: order._id
                },
                '*'
              );
              getAllOrdersService.clearData(); //to clear cached orders list data
              getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data
              $state.go('app.order-confirm', { orderId: order._id });
            }
          })
          .catch(function (err) {
            logService.error(
              'order-new-sign.component',
              'saveOrder',
              $filter('translate')('ERROR_IN_CREATING_ORDER') + ': ' + err
            );
            toastr.error($filter('translate')('ERROR_IN_CREATING_ORDER'));
          });
      }
    }

    function resendOrder() {
      vm.disableResendOrderButton = false;
      if (vm.newOrderObj && vm.newOrderObj.deliveryInfo) {
        const formattedAdditionalInfo = escapedMessageService.getEscapedMessage(
          vm.newOrderObj.deliveryInfo.information || vm.newOrderObj.additionalInfo
        );
        var updateObject = {
          orderId: vm.newOrderObj._id,
          lab: vm.newOrderObj.selectedLab,
          patient: vm.newOrderObj.patientInfo,
          constructions: {
            type: '',
            abutmentTeeth: {
              singleCrowns: [],
              bridges: [],
              posts: [],
              implantCrowns: [],
              implantBridges: [],
              completeDentures: [],
              partialDentures: [],
              temporaryDentures: [],
              bitetrays: [],
              others: [],
              otherRemovables: []
            }
          },
          additionalInfo: formattedAdditionalInfo,
          deliveryDetails: {
            date: new Date(new Date(vm.newOrderObj.deliveryInfo.date)).getTime(),
            destination: vm.newOrderObj.deliveryInfo.clinic,
            pickUpLocation: vm.newOrderObj.deliveryInfo.pickUpLocation,
            additionalInfo: formattedAdditionalInfo,
            status: vm.newOrderObj.deliveryInfo.status,
            exportOrder: vm.newOrderObj.deliveryInfo.exportOrder,
            isPickUpAtClinic: vm.newOrderObj.deliveryInfo.isPickUpAtClinic,
            isLabHandleRequest: vm.newOrderObj.deliveryInfo.isLabHandleRequest
          },
          files: vm.newOrderObj.files,
          status: vm.newOrderObj.status,
          sampleDisinfected: vm.newOrderObj.sampleDisinfected,
          rejectStatus: '',
          isNewOrder: true
        };

        updateObject.patient.personalIdentityNumber =
          vm.newOrderObj.patientInfo.personalIdentity;
        if (vm.newOrderObj.patientInfo.noPersonalIdentityNumber) {
          updateObject.patient.noPersonalIdentityNumber =
            vm.newOrderObj.patientInfo.noPersonalIdentityNumber;
        }
        updateObject.clinic = vm.newOrderObj.clinic;
        if (vm.newOrderObj.sample) {
          vm.newOrderObj.sample.created = {
            at: Date.now(),
            by: vm.currentUser.name
          };
          updateObject.deliveryDetails.sample = vm.newOrderObj.sample;
        }

        if (
          vm.newOrderObj.priceSpecification &&
          vm.newOrderObj.priceSpecification.length
        ) {
          updateObject.priceSpecification = vm.newOrderObj.priceSpecification;
        }

        if (
          vm.newOrderObj.selectedConstructions.fixedConstructions ||
          vm.newOrderObj.selectedConstructions.removableConstructions
        ) {
          if (vm.newOrderObj.selectedConstructions.type === 'fixed') {
            updateObject.constructions.type = 'Fixed';
          } else {
            updateObject.constructions.type = 'Removable';
          }

          updateObject.constructions.abutmentTeeth.singleCrowns =
            vm.newOrderObj.selectedConstructions.fixedConstructions.singleCrowns;
          updateObject.constructions.abutmentTeeth.implantCrowns =
            vm.newOrderObj.selectedConstructions.fixedConstructions.implantCrowns;
          updateObject.constructions.abutmentTeeth.bridges =
            vm.newOrderObj.selectedConstructions.fixedConstructions.bridges;
          updateObject.constructions.abutmentTeeth.posts =
            vm.newOrderObj.selectedConstructions.fixedConstructions.posts;
          updateObject.constructions.abutmentTeeth.others =
            vm.newOrderObj.selectedConstructions.fixedConstructions.others;
          updateObject.constructions.abutmentTeeth.otherRemovables =
            vm.newOrderObj.selectedConstructions.removableConstructions.otherRemovables;
          updateObject.constructions.abutmentTeeth.implantBridges =
            vm.newOrderObj.selectedConstructions.fixedConstructions.implantBridges;
          updateObject.constructions.abutmentTeeth.completeDentures =
            vm.newOrderObj.selectedConstructions.removableConstructions.completeDentures;
          updateObject.constructions.abutmentTeeth.partialDentures =
            vm.newOrderObj.selectedConstructions.removableConstructions.partialDentures;
          updateObject.constructions.abutmentTeeth.temporaryDentures =
            vm.newOrderObj.selectedConstructions.removableConstructions.temporaryDentures;
          updateObject.constructions.abutmentTeeth.bitetrays =
            vm.newOrderObj.selectedConstructions.removableConstructions.bitetrays;
          removeTempCommentsFromConstruction(
            updateObject.constructions.abutmentTeeth
          );
        }
        if (vm.inModify) {
          updateObject.isModify = true;
        } else if (vm.inResend) {
          updateObject.isResend = true;
          updateObject.isModify = false;
          updateObject.additionalInfo = formattedAdditionalInfo;
        }
        if (vm.orderIdToModify) {
          updateObject.isModify = true;
        }
        if (vm.isResend) {
          updateObject.isResend = vm.isResend;
        }

        if (vm.newOrderObj.approvedBy) {
          updateObject.approvedBy = vm.newOrderObj.approvedBy;
        }

        updateObject.dentist = vm.newOrderObj.createdBy._id;

        return orderService
          .updateOrder(updateObject)
          .then(function (result) {
            vm.disableResendOrderButton = true;
            if (vm.isResend) {
              toastr.success($filter('translate')('RESEND_ORDER_SUCCESS'));
            }
            if (vm.inModify) {
              toastr.success($filter('translate')('MODIFY_ORDER_SUCCESS'));
              window.top.postMessage(
                {
                  eventType: 'NOTIFY_MODIFY_ORDER',
                  order: order._id
                },
                '*'
              );
            }
            if (vm.orderIdToModify && !vm.isResend) {
              toastr.success($filter('translate')('MODIFY_ORDER_SUCCESS'));
            }
            getAllOrdersService.clearData(); //to clear cached orders list data
            getAllOrdersService.clearUnreadMessagesData(); //to clear cached unread messages data

            const reactAppUrl = process.env.NEW_APP_URL;
            window.location.href = `${reactAppUrl}/order-details/${updateObject.orderId}/main-view`;
          })
          .catch(function (err) {
            logService.error(
              'order-new-sign.component',
              'resendOrder',
              $filter('translate')('ERROR_IN_UPDATE_ORDER') + ': ' + err
            );
            toastr.error($filter('translate')('ERROR_IN_UPDATE_ORDER'));
          });
      }
    }

    $scope.$watch(
      'vm.newOrderDetail',
      function () {
        if (vm.newOrderDetail) {
          if (
            vm.newOrderDetail.deliveryInfo &&
            vm.newOrderDetail.deliveryInfo.clinic &&
            vm.newOrderDetail.deliveryInfo.date &&
            (vm.newOrderDetail.selectedConstructions.fixedConstructions.bridges
              .length > 0 ||
              vm.newOrderDetail.selectedConstructions.fixedConstructions
                .implantBridges.length > 0 ||
              vm.newOrderDetail.selectedConstructions.fixedConstructions
                .implantCrowns.length > 0 ||
              vm.newOrderDetail.selectedConstructions.fixedConstructions.posts
                .length > 0 ||
              vm.newOrderDetail.selectedConstructions.fixedConstructions.others
                .length > 0 ||
              vm.newOrderDetail.selectedConstructions.removableConstructions
                .otherRemovables.length > 0 ||
              vm.newOrderDetail.selectedConstructions.fixedConstructions.singleCrowns
                .length > 0 ||
              vm.newOrderDetail.selectedConstructions.removableConstructions
                .completeDentures.length > 0 ||
              vm.newOrderDetail.selectedConstructions.removableConstructions
                .partialDentures.length > 0 ||
              vm.newOrderDetail.selectedConstructions.removableConstructions
                .temporaryDentures.length > 0 ||
              vm.newOrderDetail.selectedConstructions.removableConstructions
                .bitetrays.length > 0)
          ) {
            vm.disableResendButton = false;
          } else {
            vm.disableResendButton = true;
          }
        }
      },
      true
    );

    /**
     * Method to check the deleted construction for single tooth type
     * @param newOrderSingle
     * @param originalOrderSingle
     */
    function checkSingleToothDeleted(newOrderSingle, originalOrderSingle) {
      var flag = 0;
      for (var os in originalOrderSingle) {
        var duplicate = 0;
        for (var ns in newOrderSingle) {
          if (newOrderSingle[ns].teethNo === originalOrderSingle[os].teethNo) {
            flag = 0;
            duplicate = 1;
          } else {
            flag = 1;
          }
        }
        // If the teeth is not present then mark that teeth as deleted
        if (flag && !duplicate) {
          originalOrderSingle[os].deleted = true;
        }
      }
    }

    /**
     * Method to check the deleted construction of range type constructions
     * @param newOrderRange
     * @param originalOrderRange
     */
    function checkRangeTypeTeethDeleted(newOrderRange, originalOrderRange) {
      var flag = 0;
      for (var ob in originalOrderRange) {
        var duplicate = 0;
        for (var nb in newOrderRange) {
          if (
            newOrderRange[nb].teethRange &&
            newOrderRange[nb].teethRange.start ===
              originalOrderRange[ob].teethRange.start
          ) {
            flag = 0;
            duplicate = 1;
          } else {
            flag = 1;
          }
        }
        // If the teeth is not present then mark that teeth as deleted
        if (flag && !duplicate) {
          originalOrderRange[ob].deleted = true;
        }
      }
    }

    vm.$onInit = function () {
      if ($stateParams.isResend) {
        vm.isResend = JSON.parse($stateParams.isResend);
      }
    };
  }
})();

import {
  AfterViewInit,
  Component,
  forwardRef,
  Inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import template from './order-status-view.html';
import { CUSTOMER_SETTINGS_CONSTANTS, ORDER_VERSION } from '../../app.constant';
import { PermissionService } from '../../services/core/permission.service';
import * as moment from 'moment';
import { SessionService } from '../../services/core/session.service';
import { OrganizationService } from '../../services/clinic/organization.service';
import { EventService } from '../../services/core/event.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from '../../services/translations/translations.service';
import { LogService } from '../../services/core/log.service';
import { OrderService } from '../../services/order-new/order-new.service';
import { User } from '../../models/user.model';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Organization } from '../../models/organization.model';
import { OrganizationSettingService } from '../../services/organization-setting/organization-setting.service';
import { SubNavService } from '../../services/sub-nav/sub-nav.service';
import { Order, OrderMessage } from '../../models/order.model';
import { orderBy } from 'lodash';

@Component({
  selector: 'order-status-view',
  template: template
})
export class OrderStatusViewComponent implements OnInit, AfterViewInit {
  @Input() currentUser: User;
  @ViewChild('spinner') spinner: SpinnerComponent;
  selectedClinicId = '';
  selectedStatus = '';
  selectedSample = '';
  searchText: string;
  sortType: string = '';
  sortReverse: boolean = true;
  lab: Organization;
  orders: Order[] = [];
  ordersSentByDentist: Order[] = [];
  ordersAcceptedByLab: Order[] = [];
  ordersSentByLab: Order[] = [];
  ordersApprovedByDentist: Order[] = [];
  isApprovedStatus: boolean = false;
  pageNo: number = 0;
  selectedPickUpData = {};
  SelectedOrder: Order;

  constructor(
    private permissionService: PermissionService,
    private sessionService: SessionService,
    private orderService: OrderService,
    private logService: LogService,
    private subNavService: SubNavService,
    private translationsService: TranslationsService,
    private toastr: ToastrService,
    private organizationSettingService: OrganizationSettingService,
    private eventService: EventService,
    private organizationService: OrganizationService,
    @Inject(forwardRef(() => '$state')) private $state: any
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.eventService.on('newNotificationAvailable', async (result, data) => {
        if (!data.isOrderListState) {
          await this.populateOrders();
        }
      });
      await this.addMenu();
      this.sessionService.setItem('OrderState', 'app.order-status-view');
      if (this.permissionService.isSuperAdmin(this.currentUser)) {
        this.$state.transitionTo('app.users');
      } else {
        if (this.permissionService.isLabTechnician(this.currentUser)) {
          this.lab = await this.currentLab();
        }
        await this.populateOrders();
        this.isApprovedStatus = this.organizationSettingService.getCustomerSetting(
          CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.APPROVED_STATUS
        );
      }
    } catch (err) {
      this.logService.error(
        'order-status-view.component',
        'ngOnInit',
        (await this.translationsService.get('SOMETHING_WENT_WRONG')) + ': ' + err
      );
      this.toastr.error(await this.translationsService.get('SOMETHING_WENT_WRONG'));
    }
  }

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  async onFilterUpdate(data: {
    selectedClinic: string;
    searchText: string;
    selectedStatus: string;
    selectedSample: string;
  }): Promise<void> {
    this.selectedClinicId = data.selectedClinic;
    this.searchText = data.searchText;
    this.selectedStatus = data.selectedStatus;
    this.selectedSample = data.selectedSample;
    this.spinner.show();
    await this.populateOrders();
  }

  currentLab(): Promise<Organization> {
    return this.organizationService
      .getCurrentLab()
      .then((labData) => {
        return labData.data;
      })
      .catch(async (err: any) => {
        this.logService.error(
          'order-list.component',
          'currentLab',
          (await this.translationsService.get('ERROR_IN_GETTING_LABS')) + ': ' + err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_LABS')
        );
        return undefined;
      });
  }

  populateOrders(): Promise<void> {
    if (!this.permissionService.isSuperAdmin(this.currentUser)) {
      this.selectedClinicId = this.sessionService.getItem('selectedClinicId');
      this.selectedStatus = this.sessionService.getItem('selectedStatus');
      this.selectedSample = this.sessionService.getItem('selectedSample');
      return this.orderService
        .getAllOrders({
          searchText: this.searchText,
          clinicId: this.selectedClinicId,
          statusView: true,
          orderStatus: this.selectedStatus,
          orderSample: this.selectedSample
        })
        .then(async (response) => {
          this.orders = response.data;
          if (!this.permissionService.isSuperAdmin(this.currentUser)) {
            const orderMessages = await this.getUnreadMessageData();
            this.setMessagesCount(orderMessages);
          }
          this.ordersSentByDentist = orderBy(
            this.orders.filter((order) => order.status === 'sent_by_dentist'),
            'created.on',
            'desc'
          );
          this.ordersAcceptedByLab = orderBy(
            this.orders.filter((order) => order.status === 'accepted_by_lab'),
            'created.on',
            'desc'
          );
          this.ordersSentByLab = orderBy(
            this.orders.filter((order) => order.status === 'sent_by_lab'),
            'created.on',
            'desc'
          );
          this.ordersApprovedByDentist = orderBy(
            this.orders.filter((order) => order.status === 'order_approved'),
            'created.on',
            'desc'
          );
          this.spinner.hide();
        });
    } else {
      return Promise.resolve();
    }
  }

  async addMenu(): Promise<void> {
    this.subNavService.resetList();
    this.subNavService.addMenu({
      name: await this.translationsService.get('LIST_VIEW'),
      state: 'app.order-list',
      icon: 'fa-list-ul',
      isActive: false,
      isScrollSpy: false
    });
    this.subNavService.addMenu({
      name: await this.translationsService.get('STATUS_VIEW'),
      state: 'app.order-status-view',
      icon: 'fa-plus',
      isActive: true,
      isScrollSpy: false
    });
  }

  setMessagesCount(orderMessages: OrderMessage[]): void {
    this.orders.forEach((order) => {
      orderMessages.forEach((orderMessage) => {
        orderMessage.messagesData.forEach((messageData) => {
          if (this.permissionService.isDentist(this.currentUser)) {
            if (
              (this.currentUser.generalNotifications.newMessage &&
                !messageData.type) ||
              (messageData.type && messageData.message === 'delivery_date_changed')
            ) {
              if (order._id === orderMessage.order) {
                order.unreadMessages = orderMessage.message;
              }
            }
          } else {
            const generalNotifications = this.lab.generalNotifications;
            if (this.lab) {
              if (
                (generalNotifications.newMessage && !messageData.type) ||
                (messageData.type &&
                  messageData.message === 'sent_by_dentist' &&
                  generalNotifications.newOrder) ||
                (messageData.type &&
                  messageData.message === 'order_modified' &&
                  generalNotifications.orderModified) ||
                (messageData.type &&
                  messageData.message === 'order_resend' &&
                  generalNotifications.orderResend)
              ) {
                if (order._id === orderMessage.order) {
                  order.unreadMessages = orderMessage.message;
                }
              }
            }
          }
        });
      });
    });
  }

  getUnreadMessageData(): Promise<OrderMessage[]> {
    return this.orderService
      .getUnreadMessageData()
      .then((response) => {
        return response.data || [];
      })
      .catch(async (err: any) => {
        this.logService.error(
          'order-list.component',
          'getUnreadMessageData',
          (await this.translationsService.get('ERROR_IN_GETTING_MESSAGES')) +
            ': ' +
            err
        );
        this.toastr.error(
          await this.translationsService.get('ERROR_IN_GETTING_MESSAGES')
        );
        return [];
      });
  }

  showBackgroundColor(order: Order): boolean {
    const yesterdayDate = moment().subtract(1, 'days').toDate().getTime();
    return yesterdayDate > order.deliveryDetails.date
      ? this.currentUser.viewSettings.deliveryDatePassed
      : false;
  }

  isLabTechnician(user: User): boolean {
    return this.permissionService.isLabTechnician(user);
  }

  goToOrderDetail(orderId: string): void {
    this.$state.go(CUSTOMER_SETTINGS_CONSTANTS.ORDER_DETAIL_STATES.ORDER_DETAIL2, {
      orderId: orderId
    });
  }

  async onTruckChange(): Promise<void> {
    this.orders = [];
    await this.populateOrders();
  }

  onToggleLabHandleRequest(data): void {
    this.SelectedOrder = data.order;
    if (
      this.currentUser.organization &&
      this.currentUser.organization.isTransportEnable &&
      !data.order.deliveryDetails.isLabHaveOwnResource
    ) {
      if (
        data.order.transport &&
        data.order.transport.status &&
        data.order.transport.status !== 'PLANNED'
      ) {
        return;
      }
      this.setPickUpData();
      if (data.order.transport && data.order.transport._id) {
        this.selectedPickUpData = {
          _id: data.order.transport._id,
          clinic: data.order.transport.clinic,
          clinicName: data.order.deliveryDetails.pickUpLocation.name,
          clinicContact: data.order.deliveryDetails.pickUpLocation.contact,
          comments: data.order.transport.comments,
          orderId: data.order._id,
          noOfRequestedPackages: data.order.transport.noOfRequestedPackages,
          transportDay: data.order.transport.transportDay,
          transportDate: new Date(data.order.transport.transportDay),
          dentistName: data.order.dentist
            ? data.order.dentist.name
            : data.order.created.by.name
        };
      }
      $('#schedule-pick-up-from-truck-icon-modal').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      return;
    }
    data.order.deliveryDetails.isLabHandleRequest = data.isLabHandleRequest;
    data.order.deliveryDetails.isLabHaveOwnResource = false;
    this.saveLabHandleRequest(data.order);
  }

  setPickUpData() {
    this.selectedPickUpData = {
      clinic: this.SelectedOrder.deliveryDetails.pickUpLocation._id,
      clinicName: this.SelectedOrder.deliveryDetails.pickUpLocation.name,
      clinicContact: this.SelectedOrder.deliveryDetails.pickUpLocation.contact,
      comments: null,
      orderId: this.SelectedOrder._id,
      noOfRequestedPackages: 1,
      transportDay: null,
      transportDate: null,
      dentistName: this.SelectedOrder.dentist
        ? this.SelectedOrder.dentist.name
        : this.SelectedOrder.created.by.name
    };
  }

  closePickupModal() {
    this.SelectedOrder = null;
    $('#schedule-pick-up-from-truck-icon-modal').modal('hide');
  }

  onLabHandle() {
    this.SelectedOrder.deliveryDetails.isLabHandleRequest = true;
    this.SelectedOrder.deliveryDetails.isLabHaveOwnResource = true;
    this.saveLabHandleRequest(this.SelectedOrder);
  }

  saveLabHandleRequest(order) {
    const isSimpleOrder = order.type === ORDER_VERSION.ORDER_1;
    order.labHandleReq = true;
    if (isSimpleOrder) {
      order.orderId = order._id;
      this.orderService
        .updateOrder(order)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SAVE_LAB_HANDLE_REQUEST')
          );
          await this.populateOrders();
        })
        .catch(async (err) => {
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATE_ORDER')
          );
        });
    } else {
      order.id = order._id;
      this.orderService
        .updateNewOrder(order)
        .then(async () => {
          this.toastr.success(
            await this.translationsService.get('SAVE_LAB_HANDLE_REQUEST')
          );
          window.top.postMessage(
            {
              eventType: 'NOTIFY_MODIFY_ORDER',
              order: order._id
            },
            '*'
          );
          await this.populateOrders();
        })
        .catch(async (err) => {
          this.logService.error(
            'order-status-view.component',
            'saveLabHandleRequest 1',
            err
          );
          this.toastr.error(
            await this.translationsService.get('ERROR_IN_UPDATE_ORDER')
          );
        });
    }
  }
}

/* start: imports-loader */
import '../../../app.upgrade';
import '../../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-2-delivery.html'
(function () {
    'use strict';
    angular.module('app').component('orderNew2Delivery', {
        template: theTemplate,
        controllerAs: 'vm',
        bindings: angular.extend({}, sessionStateBindings, {
            deliveryInfo: '=',
            newOrderObj: '=',
            orderIdToModify: '=',
            selectedLab: '=',
            currency: '=',
            showExportOrderCheckBox: '=',
            isPatientCategoryEnabled: '=',
            patientCategories: '=',
            sections: '=',
            labs: '=',
            orderDbId: '=',
            clinicObj: '='
        }),
        controller: orderNewDeliveryController
    });
    orderNewDeliveryController.$inject = ['$scope', 'organizationService', '$filter', 'DATE', 'orderNew2DeliveryService',
        'logService', 'toastr', 'organizationSettingService', 'CUSTOMER_SETTINGS_CONSTANTS', 'additionalInfoService', '$location',
        'permissionService', '$window', '$rootScope', 'eventService', 'ordersGraphqlService'];

    function orderNewDeliveryController($scope, organizationService, $filter, DATE, orderNew2DeliveryService,
        logService, toastr, organizationSettingService, CUSTOMER_SETTINGS_CONSTANTS, additionalInfoService, $location,
        permissionService, $window, $rootScope, eventService, ordersGraphqlService) {
        var vm = this;
        vm.defaultDateType = DATE.FORMAT;
        vm.minDate = moment().subtract(1, 'days').format("YYYY-MM-DD");
        vm.perfectDeliveryDate = new Date();
        vm.disabledDays = [];
        vm.additionalInfo = [];
        vm.myDataFromBackend = false;
        vm.fullDateTimeFormat = DATE.FULL_DATE_TIME;
        vm.isInfoChange = true;
        vm.previousConstruction = previousConstruction;
        vm.isException = isException;
        vm.nextSign = nextSign;
        vm.sortType = 'rank';
        vm.sortReverse = false;
        vm.selectedLabRank = 1;
        vm.isSelectedLabRqeuireException = false;
        vm.selectedReason = {
            "distance": false,
            "quality": false,
            "delivery-time": false,
            "patient-preference": false,
            "language": false

        };
        vm.selectedLabPriceList = [];
        vm.labExceptionsSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CHOOSE_LAB_EXCEPTIONS;
        vm.unavailableDates = [];
        vm.currentDate = new Date().toLocaleDateString();
        vm.sampleIsMandatory = true;
        vm.setReason = setReason;
        vm.validateTime = validateTime;
        vm.setFocus = setFocus;
        vm.setDate = setDate;
        vm.preventArrowKeysFunctioning = preventArrowKeysFunctioning;
        vm.checkForCorrectDate = checkForCorrectDate;
        vm.onDateChange = onDateChange;
        vm.isDateChangedManually = false;
        vm.sampleMethods = CUSTOMER_SETTINGS_CONSTANTS.SAMPLE_METHODS;
        vm.sampleMethodSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_METHOD;
        vm.sampleMethod = organizationSettingService.getCustomerSetting(vm.sampleMethodSettingName);
        vm.sampleContentSettingName = CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.SAMPLE_CONTENT;
        vm.isSampleMethodSelected = isSampleMethodSelected;
        vm.clickOnContent = clickOnContent;
        vm.toggleSampleContent = toggleSampleContent;
        vm.populateRecentlyUsedLabs = populateRecentlyUsedLabs;
        vm.saveDraft = saveDraft;
        vm.recentlyUsedLabs = [];


        function populateRecentlyUsedLabs() {
            vm.currentUser.recentlyUsedLabs.forEach((rLab) => {
                const recentLab = vm.filteredLabs.find((lab) => lab.lab._id === rLab._id);
                if (recentLab) {
                    vm.recentlyUsedLabs.push(Object.assign(JSON.parse(JSON.stringify(recentLab)), { lastUsedDate: rLab.lastUsedDate }));
                }
            });
        }

        //method when date is changed by user manually
        function onDateChange() {
            vm.isDateChangedManually = true;
        }

        function setDate() {
            if (vm.isDateChangedManually) {
                vm.selectedDate = vm.deliveryInfo.date;
                vm.isDateChangedManually = false;
            }
        }

        // listen on changes in step date and recalculate delivery date
        // eventService.on("stepDateChanged", function () {
        //     console.log('eventService');
        //     updatePerfectDeliveryDate();
        // });

        function isSampleMethodSelected() {
            if (!vm.sampleMethod) {
                return true;
            }
            return vm.newOrderObj.sample.type;
        }

        function clickOnContent(content) {
            vm.newOrderObj.sample.content.filter(function (con) {
                return con.name === content.name;
            }).forEach(function (con) {
                con.value = !con.value;
            });
        }

        function toggleSampleContent() {
            vm.newOrderObj.sample.content.forEach(function (content) {
                content.value = false;
            });
        }

        /**
         * Method to load clinic
         */
        function getClinics(clinicId) {
            organizationService.getDentistClinics({ id: clinicId }).then(function (result) {
                vm.clinics = result.data;
                var clinic, pickUpLocation;
                if (!vm.currentUser.systemSetting.clinic) {
                    if (vm.clinics.length) {
                        clinic = vm.clinics[0]._id;
                        pickUpLocation = vm.clinics[0]._id;
                    }
                } else {
                    clinic = vm.currentUser.systemSetting.clinic;
                    pickUpLocation = vm.currentUser.systemSetting.clinic;
                }

                var defaultLocationClinic = vm.clinics.find(function (orgClinic) {
                    return orgClinic._id === clinic;
                });

                //set logged in user clinic as default clinic if default clinic not set by user
                if (vm.deliveryInfo && !vm.deliveryInfo.clinic && vm.currentUser && clinic && !defaultLocationClinic.isNotPickUpAndDeliveryPlace) {
                    vm.deliveryInfo.clinic = clinic;
                    vm.deliveryInfo.pickUpLocation = pickUpLocation;
                    if (!vm.orderIdToModify) {
                        vm.deliveryInfo.date = $filter('date')(vm.currentDate, DATE.FORMAT);
                    }
                }
            }).catch(function (err) {
                logService.error('order-new-delivery.component', 'getClinics', $filter('translate')('ERROR_IN_GETTING_CLINICS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_CLINICS'));
            });
        }

        $scope.$watch('vm.filteredLabs', () => {
            if (vm.filteredLabs?.length && vm.isRecentlyUsedLabsEnabled && !vm.orderIdToModify && !vm.newOrderObj.isWarrantyOrder) {
                vm.recentlyUsedLabs = [];
                vm.populateRecentlyUsedLabs();
            }
        });

        vm.$onInit = function () {
            getOrderAdditionalInfo();
            vm.isLabUser = permissionService.isLabTechnician(vm.currentUser);
            vm.isRatingColumnEnabled = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.RATING_COLUMN);
            vm.isRecentlyUsedLabsEnabled = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.RECENT_LAB_LIST);
            if (vm.isLabUser) {
                vm.labs = [];
            } else {
                vm.isReasonForExceptionSet = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.REASON_FOR_EXCEPTION);
                getLabs();
                getClinics(vm.currentUser.organization._id);
            }
        };

        function getCategoriesToCalculateRank(lab) {
            return lab.priceList.filter(function (list) {
                return vm.newOrderObj.allConstructions.some(function (construct) {
                    return construct.mainCategory._id === list.category;
                });
            });
        }

        function getActionsToCalculateRank(categoriesToCalculateRank) {

            var actionIds = [];
            vm.newOrderObj.allConstructions.forEach(function (construct) {
                var actions = construct.actions.map(function (constructAction) {
                    return constructAction._id;
                });
                actionIds = actionIds.concat(actions);
            });
            return categoriesToCalculateRank.map(function (cat) {
                var actions = cat.actions.filter(function (action) {
                    return actionIds.indexOf(action.action) > -1;
                });
                return { category: cat.category, actions: actions };
            });
        }

        function calculateLabPrice(categoriesToCalculateRank, actionsToCalculateRank) {
            return actionsToCalculateRank.reduce(function (sum, action) {
                var actionPrice = action.actions.reduce(function (total, act) {
                    return total + act.price;
                }, 0);
                var category = categoriesToCalculateRank.find(function (cat) {
                    return cat.category === action.category;
                });
                return category ? sum + category.rank * actionPrice : sum;
            }, 0);
        }

        function checkUnavailabilityDates(lab) {
            return lab.lab.unavailableLabDates.every(function (date) {
                return new Date(date).toLocaleDateString() !== vm.currentDate;
            });
        }

        // Method to get unavailable dates of lab
        function getUnavailabilityDates(lab) {
            vm.unavailableDates = lab.lab.unavailableLabDates.map(function (date) {
                return new Date(date).toLocaleDateString();
            });
        }

        function setLabRank(areAllConstructionsFromSingleCategory, chosenCategory) {
            vm.filteredLabs.filter(function (lab) {
                return lab.lab;
            }).forEach(function (lab) {
                if (areAllConstructionsFromSingleCategory) {
                    var priceList = lab.priceList.find(function (priceList) {
                        return priceList.category === chosenCategory;
                    });
                    lab.lab.rank = priceList.rank;
                }
            });

            /**
             * filter labs according disable and unavailable dates condition
             */
            vm.filteredLabs = vm.filteredLabs.filter(function (lab) {
                getUnavailabilityDates(lab);
                return !lab.isDisabled && vm.unavailableDates.indexOf(vm.currentDate) === -1;
            });

            /**
             * we had a situation where two labs had same rank
             * so it was discussed that we keep the rank one lab as it is and increment rank of other labs
             */
            var groupedLabs = _.groupBy(vm.filteredLabs, "lab.rank");
            var sortedKeys = Object.keys(groupedLabs).sort(function (a, b) {
                return a - b;
            });
            var lastRank = 0;
            sortedKeys.forEach(function (key) {
                if (groupedLabs[key].length > 1) {
                    var labs = sortLabRankWithPrice(groupedLabs[key]);
                    labs.forEach(function (lab) {
                        lab.rank = lastRank + lab.rank;
                    });
                    lastRank = lastRank + labs.length;
                } else if (groupedLabs[key].length === 1) {
                    lastRank++;
                    groupedLabs[key][0].rank = lastRank;
                }
            });

            vm.filteredLabs = _.sortBy(vm.filteredLabs, "rank");

            /**
             *  Show lab rank in ascending order stating from 1
             */
            vm.filteredLabs.forEach(function (filteredLab, index) {
                filteredLab.lab.isRequireException = filteredLab.isRequireException;
            });
        }

        function sortLabRankWithDeliveryTime(groupLab) {
            var labsGroupedByDelDate = _.groupBy(groupLab, "deliveryDate");
            var lastRank = 0;
            var sortedKeys = Object.keys(labsGroupedByDelDate).sort(function (a, b) {
                return a - b;
            });

            sortedKeys.forEach(function (key) {
                if (labsGroupedByDelDate[key].length > 1) {
                    var labs = _.sortBy(labsGroupedByDelDate[key], "lab.name");
                    labs.forEach(function (lab) {
                        lastRank++;
                        lab.rank = lastRank;
                    });
                } else if (labsGroupedByDelDate[key].length === 1) {
                    lastRank++;
                    labsGroupedByDelDate[key][0].rank = lastRank;
                }
            });
            return groupLab;
        }

        function sortLabRankWithPrice(groupLab) {
            var labsGroupedByPrice = _.groupBy(groupLab, "price");
            var lastRank = 0;
            var sortedKeys = Object.keys(labsGroupedByPrice).sort(function (a, b) {
                return a - b;
            });
            sortedKeys.forEach(function (key) {
                if (labsGroupedByPrice[key].length > 1) {
                    var labs = sortLabRankWithDeliveryTime(labsGroupedByPrice[key]);
                    labs.forEach(function (lab) {
                        lab.rank = lastRank + lab.rank;
                    });
                    lastRank = lastRank + labs.length;
                } else if (labsGroupedByPrice[key].length === 1) {
                    lastRank++;
                    labsGroupedByPrice[key][0].rank = lastRank;
                }
            });
            return groupLab;
        }

        //get lab with rank 1
        function getRankOneLab() {
            if (vm.filteredLabs) {
                vm.newOrderObj.originalLabRank1 = vm.filteredLabs.find(function (lab) {
                    return lab.rank === 1;
                });
            }
        }

        function getAddedMaterials(constructions) {
            return constructions.reduce(function (res, construction) {
                if (!construction.addedMaterials) {
                    return res;
                }
                return res.concat(construction.addedMaterials);
            }, []);
        }

        function getPrice(constructions, lab) {
            var price = 0;
            var daysForDelivery = 0;
            var perfectDeliveryDate = new Date();
            perfectDeliveryDate.setHours(0, 0, 0, 0);
            if (constructions && constructions.length > 0 && lab.priceList) {

                var priceDiff = orderNew2DeliveryService.getPriceDiff(lab);

                var clinic = vm.clinicObj || vm.newOrderObj.clinic;

                var matGroups = clinic.materialGroups.reduce(function (res, mg) {
                    return res.concat(mg.materials);
                }, []);

                var selectedMaterials = orderNew2DeliveryService.getSelectedMaterials(constructions);

                selectedMaterials = orderNew2DeliveryService.getMaterialWithName(selectedMaterials, matGroups);
                priceDiff = orderNew2DeliveryService.getMaterialWithName(priceDiff, matGroups).filter(function (price) {
                    return selectedMaterials.some(function (mat) {
                        return mat.materialName === price.materialName;
                    });
                });

                priceDiff = orderNew2DeliveryService.getMaterialPriceDiff(vm.newOrderObj.allConstructions, priceDiff);

                //get all action selection from constructions
                var selectedActions = orderNew2DeliveryService.getSelectedActions(constructions);

                var addedMaterials = getAddedMaterials(constructions);

                //get all actions from priceList array. And get price from price list
                var allActions = orderNew2DeliveryService.getPriceList(lab.priceList);
                price = selectedActions.reduce(function (sum, selectedAction) {
                    if (selectedAction.warranty) {
                        return sum;
                    }

                    var action = allActions.find(function (action) {
                        return action.action === selectedAction._id;
                    });

                    if (!action) {
                        return sum;
                    }

                    //Check for if action per cost is enable for that action then multiply price by number of teeth selected in construction------> only for single tooth options
                    if (selectedAction.costPerTooth && selectedAction.teethNo && selectedAction.teethNo.length) {

                        var price = action.price * selectedAction.teethNo.length;
                        var vat = price * (selectedAction.vat || 0) / 100;
                        return sum + price + vat;
                    }
                    if (selectedAction.costPerTooth && selectedAction.teethRange && selectedAction.teethRange.start === 18 && selectedAction.teethRange.end === 38) {
                        //only if both up/low jaw teeth selected in construction then set quantity 2.
                        var upLowJawQuantity = 2;
                        var jawPrice = action.price * upLowJawQuantity;
                        var jawVat = jawPrice * (selectedAction.vat || 0) / 100;
                        return sum + jawPrice + jawVat;
                    }
                    if (daysForDelivery === 0) {
                        daysForDelivery = action.deliveryTime;
                    } else if (action.deliveryTime >= daysForDelivery) {
                        daysForDelivery = action.deliveryTime;
                    }
                    var aPrice = action.price * selectedAction.numberOfUnits;
                    var aVat = aPrice * (selectedAction.vat || 0) / 100;
                    return sum + aPrice + aVat;
                }, 0);

                // get price from price diff
                var diffPrice = priceDiff.reduce(function (total, mat) {
                    var matPrice = (mat.priceDiff || 0) * mat.quantity;
                    var matVatPrice = matPrice * (mat.vat || 0) / 100;
                    return total + matPrice + matVatPrice;
                }, 0);
                // get price from added materials
                var addedMaterialsPrice = addedMaterials.reduce(function (total, mat) {
                    var matPrice = (mat.price || 0) * mat.quantity;
                    var matVatPrice = matPrice * (mat.vat || 0) / 100;
                    return total + matPrice + matVatPrice;
                }, 0);

                price = price + diffPrice + addedMaterialsPrice;
            }
            // inactive days in a week
            var inActiveDays = [];
            if (lab && lab.lab && lab.lab.openingHours && lab.lab.unavailableLabDates) {
                lab.lab.openingHours.forEach(function (dayObj, index) {
                    if (!dayObj.active) {
                        var day = index + 1;
                        day = (day === 7 ? 0 : day);
                        inActiveDays.push(day);
                    }
                });
                var holiday = lab.lab.unavailableLabDates.concat(lab.lab.holidayDays || []);
                perfectDeliveryDate = orderNew2DeliveryService.countDays(daysForDelivery + 1, holiday, inActiveDays);
            }

            return {
                price: price,
                deliveryDate: perfectDeliveryDate
            };
        }

        function checkIfLabIsEligible(selectedActions, lab) {
            if (!lab.priceList || !lab.priceList.length) {
                return false;
            }
            //get all actions from priceList array.
            var allActions = orderNew2DeliveryService.getPriceList(lab.priceList);
            if (selectedActions) {
                return selectedActions.every(function (selectedAction) {
                    return allActions.some(function (action) {
                        return selectedAction._id === action.action && action.enable;
                    });
                });
            }
        }

        // Method to get current lab details for the delivery info
        $scope.$watch('vm.newOrderObj', function (newVal, oldVal) {
            if (vm.newOrderObj) {
                if (vm.newOrderObj.isStepDateChanged) {
                    updatePerfectDeliveryDate();
                }
                if (vm.newOrderObj.selectedLab) {
                    vm.currentSelectedLab = vm.newOrderObj.labData;
                    getRankOneLab();
                }
                if (vm.isLabUser && vm.newOrderObj.clinic) {
                    getClinics(vm.newOrderObj.clinic._id);
                    setLabAndSettingData(vm.newOrderObj.clinic);
                }
                if (vm.labs && vm.newOrderObj.allConstructions && vm.newOrderObj.allConstructions.length) {
                    //get all action selection from constructions
                    var selectedActions = orderNew2DeliveryService.getSelectedActions(vm.newOrderObj.allConstructions);
                    var groupedActions = _.groupBy(selectedActions, "mainCategory._id");
                    var chosenCategories = Object.keys(groupedActions);
                    var areAllConstructionsFromSingleCategory = chosenCategories.length === 1;
                    var chosenCategory;
                    if (areAllConstructionsFromSingleCategory) {
                        chosenCategory = chosenCategories[0];
                    }
                    vm.filteredLabs = vm.labs.filter(function (lab) {
                        return checkIfLabIsEligible(selectedActions, lab);
                    });
                    vm.filteredLabs.forEach(function (lab) {
                        var result = getPrice(vm.newOrderObj.allConstructions, lab);
                        lab.price = result.price;
                        lab.deliveryDate = result.deliveryDate;
                    });
                    var labPrices = vm.filteredLabs.filter(function (lab) {
                        return lab.lab;
                    }).map(function (lab) {
                        var categoriesToCalculateRank = getCategoriesToCalculateRank(lab);
                        var actionsToCalculateRank = getActionsToCalculateRank(categoriesToCalculateRank);
                        var calculatedLabPrice = calculateLabPrice(categoriesToCalculateRank, actionsToCalculateRank);
                        return { lab: lab.lab, price: calculatedLabPrice };
                    }).filter(function (labPrice) {
                        return labPrice.price !== 0;
                    });
                    labPrices = _.sortBy(labPrices, "price");
                    if (!vm.isLabUser) {
                        setLabRank(areAllConstructionsFromSingleCategory, chosenCategory);
                    }
                    if (!vm.orderIdToModify) {
                        if (vm.newOrderObj.labRank === 1) {
                            var lab = vm.filteredLabs.find(function (lab) {
                                return lab.lab && lab.rank === 1 && !lab.isDisabled && checkUnavailabilityDates(lab);
                            });
                            if (lab) {
                                vm.selectedLab = lab.lab._id;
                            }
                        }
                    }
                }
                if (newVal && newVal.allConstructions && oldVal && oldVal.allConstructions) {
                    var newConstructLen = newVal.allConstructions.filter(function (construct) {
                        return construct.createdAt;
                    }).length;
                    var oldConstructLen = oldVal.allConstructions.filter(function (construct) {
                        return construct.createdAt;
                    }).length;
                    if (newConstructLen !== oldConstructLen) {
                        updatePerfectDeliveryDate();
                    }
                }
            }
        }, true);

        // To get the delivery date of selected lab
        function updatePerfectDeliveryDate() {
            if (vm.labs && vm.currentSelectedLab) {
                var additionalDeliveryDate = 0;
                if (vm.deliveryInfo.exportOrder) {
                    additionalDeliveryDate = vm.currentSelectedLab.export.additionalDeliveryDays;
                }
                var selectedLab = vm.labs.find(function (lab) {
                    return lab.lab && lab.lab._id === vm.currentSelectedLab._id;
                });
                // if lab is found then add price list.
                if (selectedLab) {
                    vm.selectedLabPriceList = selectedLab.priceList;
                }
                var result = orderNew2DeliveryService.updatePerfectDeliveryDate(vm.currentSelectedLab, vm.newOrderObj, vm.deliveryInfo, additionalDeliveryDate, vm.selectedLabPriceList);
                if (result) {
                    vm.newOrderObj = result.newOrderObj;
                    vm.deliveryInfo = result.deliveryInfo;
                    vm.perfectDeliveryDate = result.perfectDeliveryDate;
                    if (vm.additionalInfo && vm.additionalInfo.length) {
                        vm.deliveryInfo.information = '';
                    }

                    //Don't change delivery date according to calculated delivery date when modifying order
                    if (vm.orderIdToModify) {
                        vm.deliveryInfo.date = $filter('date')(vm.newOrderObj.savedDeliverydate, DATE.FORMAT);
                        vm.perfectDeliveryDate = new Date(vm.newOrderObj.savedDeliverydate).setHours(0, 0, 0, 0);
                    }

                    // To get the delivery date according to the selected steps date for selected lab of order
                    if (vm.newOrderObj.allConstructions && vm.newOrderObj.allConstructions.length) {
                        var stepDate, selectedMinStepDateForDelivery;
                        var minConstructionStepDate = vm.newOrderObj.allConstructions.map(function (construction) {
                            var dentureDate, implantDate, testingDate;
                            if (construction.dentureDates) {
                                dentureDate = getStepDates(construction.dentureDates, construction.dentureSteps);
                            }
                            if (construction.implantDates) {
                                implantDate = getStepDates(construction.implantDates, construction.implantSteps);
                            }
                            if (construction.testingDates) {
                                testingDate = getStepDates(construction.testingDates, construction.testingSteps);
                            }
                            if (dentureDate || implantDate || testingDate) {
                                return Math.min(dentureDate || Infinity, implantDate || Infinity, testingDate || Infinity);
                            }
                        });
                        minConstructionStepDate = minConstructionStepDate.filter(function (minDate) {
                            return minDate !== undefined;
                        });

                        if (minConstructionStepDate.length && minConstructionStepDate[0] !== undefined) {
                            stepDate = Math.min.apply(null, minConstructionStepDate);
                            vm.deliveryInfo.date = moment(stepDate).format("YYYY-MM-DD");
                            vm.selectedDate = vm.deliveryInfo.date;
                        }
                    }
                }
                vm.disabledDays = getDisabledDates(vm.currentSelectedLab);
                vm.disableWeekDays = getDisabledWeekDays(vm.currentSelectedLab);
            }
        }

        /**
         * Method for getting steps date
         * @param dates
         * @param step
         * @returns {number}
         */
        function getStepDates(dates, step) {
            var datesKeys = Object.keys(dates);
            if (datesKeys.length) {
                var stepKey = datesKeys.find(function (key) {
                    return key === step;
                });
            }
            return new Date(dates[stepKey]).getTime();
        }

        // To get the delivery date according to actions of all connected labs
        $scope.$watch('vm.newOrderObj.selectedActions', function (newVal) {
            // check if there is new Selected Actions then call it
            if (newVal) {
                if (vm.labs && vm.labs.length > 0) {
                    var additionalDeliveryDate = 0;
                    if (vm.deliveryInfo.exportOrder && vm.currentSelectedLab) {
                        additionalDeliveryDate = vm.currentSelectedLab.export.additionalDeliveryDays;
                    }
                    vm.labs.forEach(function (lab) {
                        if (!vm.selectedDate) {
                            var result = orderNew2DeliveryService.updatePerfectDeliveryDate(lab.lab, vm.newOrderObj, vm.deliveryInfo, additionalDeliveryDate, lab.priceList);
                        }
                        if (result) {
                            lab.lab.deliveryDate = result.deliveryInfo.date;

                            //Don't change delivery date according to calculated delivery date when modifying order
                            if (vm.orderIdToModify) {
                                lab.lab.deliveryDate = $filter('date')(vm.newOrderObj.savedDeliverydate, DATE.FORMAT);
                            }

                            // To get the delivery date according to selected steps date for all connected labs
                            if (vm.newOrderObj.allConstructions && vm.newOrderObj.allConstructions.length) {
                                var stepDate, selectedMinStepDateForDelivery;
                                var minConstructionStepDate = vm.newOrderObj.allConstructions.map(function (construction) {
                                    var dentureDate, implantDate, testingDate;
                                    if (construction.dentureDates) {
                                        dentureDate = getStepDates(construction.dentureDates, construction.dentureSteps);
                                    }
                                    if (construction.implantDates) {
                                        implantDate = getStepDates(construction.implantDates, construction.implantSteps);
                                    }
                                    if (construction.testingDates) {
                                        testingDate = getStepDates(construction.testingDates, construction.testingSteps);
                                    }
                                    if (dentureDate || implantDate || testingDate) {
                                        return Math.min(dentureDate || Infinity, implantDate || Infinity, testingDate || Infinity);
                                    }
                                });
                                minConstructionStepDate = minConstructionStepDate.filter(function (minDate) {
                                    return minDate !== undefined;
                                });
                                if (minConstructionStepDate.length && minConstructionStepDate[0] !== undefined) {
                                    stepDate = Math.min.apply(null, minConstructionStepDate);
                                    lab.lab.deliveryDate = moment(stepDate).format("YYYY-MM-DD");
                                }
                            }
                        }
                    });
                }

                if (!vm.selectedDate) {
                    updatePerfectDeliveryDate();
                }
            }
        }, true);

        function getDisabledDates(lab) {
            var disabledDays = [];
            if (lab.holidayDays.length) {
                for (var day in lab.holidayDays) {
                    disabledDays.push(new Date(lab.holidayDays[day]));
                }
                vm.myDataFromBackend = true;
            }
            if (lab.unavailableLabDates.length) {
                for (var unavailableDay in lab.unavailableLabDates) {
                    disabledDays.push(new Date(lab.unavailableLabDates[unavailableDay]));
                }
                vm.myDataFromBackend = true;
            }
            return disabledDays;
        }

        function getDisabledWeekDays(lab) {
            var disabledWeekDays = [];
            if (lab.openingHours && lab.openingHours.length) {
                disabledWeekDays = lab.openingHours.filter(function (dayObj, index) {
                    var day = index + 1;
                    day = (day === 7 ? 0 : day);
                    dayObj.dayNum = day;
                    return !dayObj.active;
                }).map(function (dayObj) {
                    return dayObj.dayNum;
                });
            }
            return disabledWeekDays;
        }

        // for Modify order watch current selected lab to get the perfect delivery date
        $scope.$watch('vm.currentSelectedLab', function (newVal, oldVal) {
            // check if there is change in new lab

            //default selection of "Pick up requested?" if available for that clinic
            vm.client = vm.currentSelectedLab && vm.currentSelectedLab.clientSettings && vm.currentSelectedLab.clientSettings.find((clientSetting) => clientSetting.clinicId === vm.deliveryInfo.pickUpLocation);
            vm.deliveryInfo.isPickUpAtClinic = !!(vm.client && vm.client.transportProvider && vm.client.transportProvider.channel);
            if (newVal && vm.labs) {
                if (vm.orderIdToModify) {
                    // since lab cannot be changed once the order is created, show only single lab that was selected
                    var lab = vm.labs.find(function (lab) {
                        return lab.lab && lab.lab._id === vm.newOrderObj.selectedLab;
                    });
                    if (lab && lab.lab) {
                        lab.lab.deliveryDate = $filter('date')(vm.newOrderObj.savedDeliverydate, DATE.FORMAT);
                        vm.disabledDays = getDisabledDates(lab.lab);
                        vm.disableWeekDays = getDisabledWeekDays(lab.lab);
                        vm.labs = [lab];
                    }

                    vm.deliveryInfo.date = $filter('date')(vm.selectedDate || vm.newOrderObj.savedDeliverydate, DATE.FORMAT);

                    if (vm.isLabUser) {
                        vm.deliveryInfo.price = vm.newOrderObj.savedPrice;
                    }
                    //saved DB value selection of "Pick up requested?"
                    vm.deliveryInfo.isPickUpAtClinic = vm.newOrderObj.savedPickUpAtClinicsValue;
                } else if (vm.newOrderObj.isWarrantyOrder && vm.newOrderObj.status === 'draft') {
                    // since lab cannot be changed for warranty order
                    var savedLab = vm.labs.find(function (lab) {
                        return lab.lab && lab.lab._id === vm.newOrderObj.selectedLab;
                    });
                    if (savedLab && savedLab.lab) {
                        vm.disabledDays = getDisabledDates(savedLab.lab);
                        vm.disableWeekDays = getDisabledWeekDays(savedLab.lab);
                        vm.labs = [savedLab];
                        updatePerfectDeliveryDate();
                    }
                } else {
                    if (!oldVal || oldVal && oldVal._id !== newVal._id) {
                        updatePerfectDeliveryDate();
                    }
                }
            }
        }, true);

        // Watch delivery date to check the date which is perfect delivery date or not
        $scope.$watch('vm.newOrderObj.deliveryInfo.date', function () {
            if (vm.newOrderObj && vm.newOrderObj.deliveryInfo) {
                var selectedDate = new Date(vm.newOrderObj.deliveryInfo.date);
                selectedDate = selectedDate.getTime();
                vm.perfectDeliveryDate = new Date(vm.perfectDeliveryDate);
                vm.perfectDeliveryDate = vm.perfectDeliveryDate.setHours(0, 0, 0, 0);
                vm.deliveryInfo.status = selectedDate < vm.perfectDeliveryDate ? 'early_delivery' : '';
                //Don't change delivery date according to calculated delivery date when modifying order
                //change when manually changed date by date picker or step date
                if (vm.orderIdToModify && vm.newOrderObj.deliveryInfo.date === vm.newOrderObj.savedDeliverydate) {
                    vm.deliveryInfo.date = $filter('date')(vm.newOrderObj.savedDeliverydate, DATE.FORMAT);
                    vm.perfectDeliveryDate = new Date(vm.newOrderObj.savedDeliverydate).setHours(0, 0, 0, 0);
                }
            }
        }, true);

        $scope.$watch('vm.deliveryInfo.exportOrder', function (newVal, oldVal) {
            // if there is a change in export order checkbox
            if (typeof newVal === 'boolean' && typeof oldVal === 'boolean' && newVal !== oldVal) {
                updatePerfectDeliveryDate();
            }
        }, true);

        // function to get the additional info of the current order
        function getOrderAdditionalInfo() {
            if (vm.newOrderObj) {
                vm.additionalInfo = additionalInfoService.getAdditionalInfo(vm.newOrderObj);
            }
        }

        function infoChange() {
            if (vm.isInfoChange && vm.deliveryInfo && vm.deliveryInfo.information && vm.additionalInfo.length) {
                vm.isInfoChange = false;
                vm.deliveryInfo.information = '';
            }
        }

        $scope.$watch('vm.deliveryInfo.information', function (newVal, oldVal) {
            // if there is a change in information
            if (newVal) {
                infoChange();
            }
        }, true);

        //for modify order set additional info value from DB
        $scope.$watch('vm.newOrderObj.additionalInfo', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                vm.deliveryInfo.information = newVal;
            }
        }, true);

        function setLabAndSettingData(org) {
            vm.currency = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CURRENCY);
            vm.labExceptions = organizationSettingService.getCustomerSetting(CUSTOMER_SETTINGS_CONSTANTS.SETTINGS_NAMES.CHOOSE_LAB_EXCEPTIONS);
            if (org.connectedLabs) {
                if (!vm.newOrderObj.selectedActions) {
                    vm.newOrderObj.selectedActions = [];
                }
                if (!vm.isLabUser) {
                    orderNew2DeliveryService.calculateLabRatings(org.connectedLabs, org._id);
                }
                vm.labs = org.connectedLabs.filter(function (lab) {
                    return lab.priceList && lab.priceList.length !== 0;
                });
            }
        }

        /**
         * Method to load labs
         */
        function getLabs() {
            organizationService.getConnectedLabsForClinic({ organizationId: vm.currentUser.organization._id }).then(function (result) {
                vm.clinicObj = result.data;
                setLabAndSettingData(result.data);
            }).catch(function (err) {
                logService.error('order-new-2-delivery.component', 'getLabs', $filter('translate')('ERROR_IN_GETTING_LABS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_LABS'));
            });
        }

        //previous step
        function previousConstruction() {
            vm.sections.showPatientSections = false;
            vm.sections.showConstructionSections = true;
            vm.sections.showDeliverySections = false;
            vm.sections.showSignSendSections = false;
        }

        function createDeliveryDataToSave() {
            return {
                date: new Date(vm.deliveryInfo.date).getTime(),
                pickUpLocation: vm.deliveryInfo.pickUpLocation,
                destination: vm.deliveryInfo.clinic,
                exportOrder: vm.deliveryInfo.exportOrder,
                sample: vm.newOrderObj.sample,
                reason: vm.newOrderObj.deliveryInfo.reason,
                time: vm.deliveryInfo.time,
                status: vm.newOrderObj.deliveryInfo.status,
                isPickUpAtClinic: vm.newOrderObj.deliveryInfo.isPickUpAtClinic,
                isLabHandleRequest: vm.newOrderObj.deliveryInfo.isLabHandleRequest,
            };
        }

        async function saveDraft() {
            if (!vm.orderIdToModify &&
                vm.selectedLab &&
                vm.deliveryInfo.date &&
                vm.deliveryInfo.pickUpLocation &&
                vm.deliveryInfo.clinic && vm.isException() && isSampleMethodSelected()) {
                const data = createDeliveryDataToSave();
                await ordersGraphqlService.saveDeliveryToDraft(vm.orderDbId, vm.newOrderObj.selectedLab, data);
                toastr.success($filter('translate')('DRAFT_SAVED'));
            }
        }

        //next step
        async function nextSign() {
            if (vm.selectedLab &&
                vm.deliveryInfo.date &&
                vm.deliveryInfo.pickUpLocation &&
                vm.deliveryInfo.clinic && vm.isException() && isSampleMethodSelected()) {
                await saveDraft();
                vm.sections.showPatientSections = false;
                vm.sections.showConstructionSections = false;
                vm.sections.showDeliverySections = false;
                vm.sections.showSignSendSections = true;
                getOrganizationDetails(vm.newOrderObj.deliveryInfo.pickUpLocation, 'pickUp');
                getOrganizationDetails(vm.newOrderObj.deliveryInfo.clinic, 'delivery');
                getOrganizationDetails(vm.newOrderObj.selectedLab, 'lab');
            }
        }

        // Selected reason for exception
        function setReason(value) {
            vm.selectedReason[value] = !vm.selectedReason[value];
            vm.deliveryInfo.reason = vm.selectedReason;
            vm.deliveryInfo.exception = Object.keys(vm.deliveryInfo.reason).some(function (reason) {
                return vm.deliveryInfo.reason[reason];
            });
        }

        $scope.$watch('vm.newOrderObj.sample.type', function (newVal, oldVal) {
            //Deselect selection of "Pick up requested?" if sample type is DIGITAL Or NO_SAMPLE
            vm.client = vm.currentSelectedLab && vm.currentSelectedLab.clientSettings && vm.currentSelectedLab.clientSettings.find((clientSetting) => clientSetting.clinicId === vm.deliveryInfo.pickUpLocation);
            if (vm.client && vm.client.transportProvider && vm.client.transportProvider.channel) {
                if (vm.newOrderObj.sample.type === 'DIGITAL') {
                    vm.deliveryInfo.isPickUpAtClinic = false;
                } else if (vm.newOrderObj.sample.type === 'NO_SAMPLE') {
                    vm.deliveryInfo.isPickUpAtClinic = false;
                } else if (vm.newOrderObj.sample.type === 'PHYSICAL') {
                    vm.deliveryInfo.isPickUpAtClinic = true;
                }
            } else {
                vm.deliveryInfo.isPickUpAtClinic = false;
            }
        }, true);

        //Get organization details
        function getOrganizationDetails(id, type) {
            organizationService.getOrganization({ id: id }).then(function (result) {
                if (type === 'pickUp') {
                    vm.newOrderObj.deliveryInfo.selectedPickUpDetails = { name: result.data.name, countryCode: result.data.countryCode, contact: result.data.contact };
                }
                if (type === 'delivery') {
                    vm.newOrderObj.deliveryInfo.selectedDeliveryDetails = { name: result.data.name, countryCode: result.data.countryCode, contact: result.data.contact };
                }
                if (type === 'lab') {
                    vm.newOrderObj.deliveryInfo.selectedLabName = result.data.name;
                }

            }).catch(function (err) {
                logService.error('order-new-2-delivery.component', 'getOrganizationDetails', $filter('translate')('ERROR_IN_GETTING_CLINIC_DETAILS') + ": " + err);
                toastr.error($filter('translate')('ERROR_IN_GETTING_CLINIC_DETAILS'));
            });
        }

        function isException() {
            if (!vm.isReasonForExceptionSet) {
                return true;
            }
            if (vm.isSelectedLabRqeuireException) {
                return true;
            }
            return vm.selectedLabRank !== 1 ? vm.deliveryInfo.exception : true;
        }

        function checkIfSelectedDateIsTodayDate(selectedDate) {
            if (selectedDate) {
                var dateParts = selectedDate.split("-");
                var year = dateParts[0];
                var month = dateParts[1];
                var day = dateParts[2];
                var tDate = new Date();
                var tYear = tDate.getYear() + 1900;
                var tMonth = tDate.getMonth() + 1;
                var tDay = tDate.getDate();
                if (tMonth < 10) {
                    tMonth = "0" + tMonth.toString();
                }
                if (tDay < 10) {
                    tDay = "0" + tDay.toString();
                }
                return tDay === day && tMonth === month && tYear.toString() === year;
            }
            return false;
        }

        function checkForCorrectDate() {
            var isTodayDate = checkIfSelectedDateIsTodayDate(vm.deliveryInfo.date);
            var currentTime = new Date();
            if (isTodayDate && vm.deliveryInfo.time) {
                var cHour = currentTime.getHours();
                var isWrongHour = vm.deliveryInfo.time.hour < cHour;
                return isWrongHour || vm.deliveryInfo.time.hour === cHour && vm.deliveryInfo.time.minute < currentTime.getMinutes();
            }
            return false;
        }

        /**
         * Method for validate Time HH:MM (23:59)
         * @param type
         * @param event
         */
        function validateTime(type, event) {
            var curValue = String.fromCharCode(event.which);
            if (isNaN(curValue)) {
                event.preventDefault();
            } else {
                if (type === 'hour') {
                    var existingHourValue = event.target.value;
                    var expectedHour = existingHourValue.toString().concat(curValue);
                    if (parseInt(expectedHour) > 23) {
                        event.preventDefault();
                    } else if (event.target.value <= 9) {
                        event.target.value = parseInt('0'.concat(event.target.value));
                    }
                } else {
                    var existingMinuteValue = event.target.value;
                    var expectedMinute = existingMinuteValue.toString().concat(curValue);
                    if (parseInt(expectedMinute) > 59) {
                        event.preventDefault();
                    } else if (event.target.value <= 9) {
                        event.target.value = parseInt('0'.concat(event.target.value));
                    }
                }
            }

        }

        /**
         * Set focus on minute input after filled hour input
         */
        function setFocus(hourId, minuteId) {
            var hour = $window.document.getElementById(hourId);
            var minute = $window.document.getElementById(minuteId);
            if (hour && hour.value.length === 2 && parseInt(hour.value) > 2) {
                minute.focus();
            }
        }

        /**
         * Prevent up/down arrow keys on time input
         * @param event
         */
        function preventArrowKeysFunctioning(event) {
            if (event.which === 38 || event.which === 40) {
                event.preventDefault();
            }
        }

        $scope.$watch('vm.deliveryInfo.pickUpLocation', function (newVal, oldVal) {
            //default selection of "Pick up requested?" if available for that clinic
            vm.client = vm.currentSelectedLab && vm.currentSelectedLab.clientSettings && vm.currentSelectedLab.clientSettings.find((clientSetting) => clientSetting.clinicId === vm.deliveryInfo.pickUpLocation);
            vm.deliveryInfo.isPickUpAtClinic = !!(vm.client && vm.client.transportProvider && vm.client.transportProvider.channel);
        }, true);

        // Method to check the current lab data
        $scope.$watch('vm.selectedLab', function () {
            if (vm.selectedLab) {
                organizationService.getOrganization({ id: vm.selectedLab }).then(function (result) {
                    vm.newOrderObj.labData = result.data;
                }).catch(function (err) {
                    logService.error('order-new-2-delivery.component', '$watch vm.selectedLab', $filter('translate')('ERROR_IN_GETTING_LAB') + ": " + err);
                    toastr.error($filter('translate')('ERROR_IN_GETTING_LAB'));
                });
            }
        });
    }
})();
